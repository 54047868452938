import { checkForMaintenanceMode } from "@/features/maintenance-mode/mm-redirect";
import { storeWrapper } from "@rototip/lib-redux";
import { PreloaderOverlay } from "@rototip/lib-ui/common/PreloaderOverlay";
import sanitizeCallback from "@rototip/lib-ui/src/helpers/sanitizeCallback";
import { NextPage } from "next";
import { getServerSession } from "next-auth";
import type { BuiltInProviderType } from "next-auth/providers/index";
import {
	ClientSafeProvider,
	getCsrfToken,
	getProviders,
	LiteralUnion,
	signIn,
} from "next-auth/react";
import Head from "next/head";
import { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { getAuthOptions } from "../api/auth/[...nextauth]";

const SignInForm = lazy(() => import("@rototip/lib-ui/auth/SignInForm"));

type SignInPageProps = {
	providers?: Record<
		LiteralUnion<BuiltInProviderType, string>,
		ClientSafeProvider
	> | null;
	csrfToken?: string;
};

const SignInPage: NextPage<SignInPageProps> = ({ providers, csrfToken }) => {
	return (
		<>
			<Head>
				<title>Sign In | Rototip Web App</title>
			</Head>
			<Suspense fallback={<PreloaderOverlay fit="screen" />}>
				<SignInForm
					providers={providers}
					signIn={signIn}
					csrfToken={csrfToken}
				/>
			</Suspense>
		</>
	);
};

export const getServerSideProps = storeWrapper.getServerSideProps(
	(store) =>
		async (context): Promise<{ props: SignInPageProps }> => {
			const maintenanceModeRedirect = await checkForMaintenanceMode();
			if (maintenanceModeRedirect) return maintenanceModeRedirect;
			const { req, res, query } = context;
			const session = await getServerSession(req, res, getAuthOptions(req));
			const { callbackUrl } = query;

			if (session && res && session.user) {
				let redirectUrl = sanitizeCallback(
					callbackUrl,
					process.env.NEXTAUTH_URL || req.headers.host
				);

				res.writeHead(302, {
					Location: redirectUrl,
				});
				res.end();
				return { props: {} };
			}

			const [providers, csrfToken] = await Promise.all([
				getProviders(),
				getCsrfToken(context),
			]);

			return {
				props: { csrfToken, providers },
			};
		}
);

export default connect((state) => state)(SignInPage);
